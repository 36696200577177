import React from 'react';
import TextComponent, {
  TextProps,
  TextFieldGlimmer as TextFieldGlimmerComponent,
} from '../../components/fields/text/Text';
import '../../../../stylesheets/components/textField.module.scss';
import editStyles from '../../../../stylesheets/components/edit.module.scss';
import glimmerStyles from '../../../../stylesheets/components/glimmer.module.scss';
import classNames from 'classnames';
import { useThemeContext } from '../../components/ThemeContextProvider';

const Text = ({
  field,
  mode,
  onChange,
  onKeyDown,
  testClass,
  errors,
}: TextProps) => {
  // this is temporary- we should decide whether to use a context for login page
  // or to pass a prop that is used if usePortalContext is undefined, or some other solution
  let { theme } = useThemeContext();
  const browserTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
  theme ||= browserTheme;
  const variant = theme === 'dark' ? 'filled' : 'outlined';
  return (
    <TextComponent
      field={field}
      mode={mode}
      variant={variant}
      onChange={onChange}
      onKeyDown={onKeyDown}
      editClassNames={editStyles.pmEdit}
      testClass={testClass}
      errors={errors}
    />
  );
};

export const TextFieldGlimmer = () => {
  return (
    <TextFieldGlimmerComponent
      editClassNames={editStyles.pmEdit}
      glimmerClassNames={classNames(
        glimmerStyles.textField,
        glimmerStyles.glimmerElement
      )}
    />
  );
};

export default Text;
